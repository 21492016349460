<template>
  <div>
    <!-- 第一行 标题 -->
    <el-row>
      <el-col :span="24">
        <div
          class="grid-content center"
          style="
            font-size: 30px;
            color: #606266;
            font-weight: bold;
            letter-spacing: 3px;
            margin-top: 20px;
          "
        >
          评卷客户端管理
        </div>
      </el-col>
    </el-row>
    <!-- 第二行 说明 -->
    <el-row>
      <el-col :span="24">
        <div class="grid-content" style="color: #1890ff; font-size: 15px">
          操作说明：1、添加设备 2、设置科目对应设备 3、分配任务 4、缓存任务下发
        </div>
      </el-col>
    </el-row>
    <!-- 第三行 主要内容 -->
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple" style="margin-top: 100px">
          <!-- 主内容第一行 图片 -->
          <el-row :gutter="20" style="padding-top: 100px">
            <el-col :span="12" :offset="6">
              <div class="grid-content center">
                <img src="@/assets/clientPic.png" alt />
              </div>
            </el-col>
          </el-row>
          <!-- 主内容第二行 暂无设备 -->
          <el-row :gutter="20">
            <el-col :span="12" :offset="6">
              <div class="grid-content center">
                <span
                  style="font-size: 34px; color: #cbcbcb; letter-spacing: 3px"
                  >暂无设备</span
                >
              </div>
            </el-col>
          </el-row>
          <!-- 主内容第三行 添加设备及增减 -->
          <el-row :gutter="20">
            <el-col :span="12" :offset="6">
              <div
                class="grid-content center"
                style="margin-top: 20px; margin-bottom: 80px"
              >
                <el-input-number
                  v-model="num"
                  @change="handleChange"
                  :min="1"
                  :max="999"
                  label="描述文字"
                ></el-input-number>
                <el-button
                  v-throttle
                  type="primary"
                  style="margin-left: 20px"
                  @click="jumpMain"
                  >添加设备</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 1,
      examId: 0,
    }
  },
  mounted() {
    this.examId = Number(this.$route.query.examId)
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    //路由跳转
    jumpMain() {
      this.$router.push({
        path: '/marking/markingClient/clientManage',
        query: { seatNum: this.num, examId: this.examId },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  margin-left: 40px;
  margin-right: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: rgba(223, 230, 236, 0.16);
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.center {
  text-align: center;
}

::v-deep .el-input-number {
  border: 3px solid #1890ff;
  border-radius: 5px 5px 5px 5px;
}

::v-deep .el-input-number__increase {
  background-color: #ffffff;
}

::v-deep .el-input-number__decrease {
  background-color: #ffffff;
}
</style>
